<template>
  <page-main
    title="Submit a Custom Resume"
    :aside="[
      `Upload any resume and get it reviewed by the ${displayName} Career Center`,
    ]"
    buttonTabIndex="5"
    eventName="nav"
    :nextLabel="false"
    buttonText="Submit Resume"
    :error="error"
    @nav="submitResume()"
  >
    <v-container>
      <v-overlay color="primary" :absolute="true" v-if="overlay">
        <v-progress-circular
          indeterminate
          color="accent"
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="name"
            label="Give a name to this version of your Resume"
            :rules="nameRules"
            tabindex="1"
            autofocus
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-textarea
            v-model="note"
            :label="`Notes for the ${displayName} Career Center (Optional)`"
            tabindex="2"
            rows="3"
            autogrow
          ></r-textarea>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field v-model="curDate" label="Date Submitted" disabled>
          </r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-file-input
            v-model="resumeFile"
            :rules="fileRules"
            tabindex="3"
            accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            label="Upload your Resume"
          ></r-file-input>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
import * as careerApi from "../../api/CareerApiUser";
import * as log from "../../utility/logging/logger";
import { mapFields } from "vuex-map-fields";
import { required } from "../../utility/validation.js";

// @ is an alias to /src
export default {
  name: "SubmitNewResume",
  data: () => ({
    name: "",
    note: "",
    curDate: "",
    overlay: false,
    error: "",
    errorText:
      "Oops! An unexpected error occured. You can try again, or try with a different file.",
    validTypes: [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
    nameRules: [(value) => required(value)],
    resumeFile: new File([], ""),
  }),
  components: {},
  mounted() {
    let d = new Date();
    this.curDate = d.toLocaleDateString("en-us");
  },
  methods: {
    checkFileType(value) {
      return (
        this.validTypes.some((t) => t === value?.type) ||
        "Only PDF or DOCX files are supported."
      );
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>
          resolve(reader.result.slice(reader.result.indexOf("base64,", 0) + 7));
        reader.onerror = (error) => reject(error);
      });
    },
    async submitResume() {
      if (!this.canProceedEmail) {
        this.error =
          "You must confirm your email address to proceed. You can re-send a confirmation email from the User Options page.";
        this.overlay = false;
        return;
      }

      this.error = "";
      this.overlay = true;
      try {
        const fileBase64 = await this.toBase64(this.resumeFile);
        var result = await careerApi.CreateReview(
          this.name,
          this.note,
          fileBase64,
          this.resumeFile.type,
          this.resumeFile.name
        );
        if (!result.success) {
          this.error = this.errorText;
        } else {
          this.nav("/SubmittedResumes");
        }
      } catch (er) {
        log.logError(
          `Error in submitResume: ${er}`,
          "SubmitNewResume.submitResume"
        );
        this.error = this.errorText;
      } finally {
        this.overlay = false;
      }
    },
  },
  computed: {
    fileRules() {
      return [
        (v) => !v || v?.size < 2000000 || "Resume size must be less than 2 MB.",
        (v) => {
          return (
            v?.name?.length > 0 || "Upload a Resume file to get it reviewed."
          );
        },
        this.checkFileType,
      ];
    },
    ...mapFields("envStyle", ["displayName"]),
    ...mapFields("auth", {
      emailConfirmed: "emailConfirmed",
      authType: "auth.authType",
    }),
    canProceedEmail() {
      return this.authType != "resumation" || this.emailConfirmed;
    },
  },
};
</script>


<style scoped>
.resume-img {
  background-color: var(--v-resumeBackground-base);
  z-index: 1;
}
</style>